:root {
  --color: white;
  --border: #141c27;
  --lighter-border: #233043;

  --gray-color: #fffc;
  --gray-background: #c8c8dc1a;
  --gray-dark-background: #161a20;
  --tippy-background: #1d202c;
  --background: #171717;
  --header-button-background: #20242a;
  --dark-background: #14161d;
  --box-background: #1f2128;

  --anchor-color: #8b5cf6;
}

html,
body {
  margin: 0;
  padding: 0;

  height: 100%;
}

body {
  color: var(--color);
  background-color: var(--background);

  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  color: var(--color);
  background-color: var(--background);
}

body,
input,
textarea,
button {
  font: 18px/1.25 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
}

.shown {
  display: flex !important;
}

.hidden {
  display: none !important;
}

a {
  text-decoration: none;
  color: var(--color);
}

.fill-image {
  width: 100%;
  height: 100%;

  border-radius: 16px;
  object-fit: fill;
}

.tippy-box[data-theme~="core"] {
  background-color: var(--tippy-background);
  color: var(--color);

  font-size: 16px;
  border-radius: 8px;
}

.tippy-box[data-theme~="core"][data-placement^="top"] > .tippy-arrow::before {
  border-top-color: var(--tippy-background);
}

.tippy-box[data-theme~="core"][data-placement^="bottom"] > .tippy-arrow::before {
  border-bottom-color: var(--tippy-background);
}
